import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "animate.css";
import slide1 from "../assets/slide1.jpg";
import slide2 from "../assets/slide2.jpg";
import slide3 from "../assets/slide3.jpg";
import { useInView } from "react-intersection-observer";

const slideImages = [slide1, slide2, slide3];

const About = () => {
  return (
    <div id="about" className="h-screen flex flex-col mb-20">
      <h2 className="text-4xl font-bold mt-20  self-center text-[#023047]">
        За нас
      </h2>
      <div className="flex  items-center h-full flex-col md:flex-row ">
        <div className="w-full flex flex-col md:w-1/3 p-4 animate__animated animate__fadeInLeft">
          {/* Text Content */}
          <p className="ml-10 mr-10 text-2xl tracking-wider">
            Адвокатската канцеларија Адвокат Шериф Салоски е со седиште во
            Струга. Основач на адвокатската канцеларија е адвокат м-р Шериф
            Салоски. Посветени сме во обезбедување на сеопфатни правни услуги на
            правни и физички лица од земјава и странство како и заштита на
            нивните права и интереси.{" "}
          </p>
        </div>

        <div className="w-full md:w-2/3 p-4 animate__animated animate__fadeInRight">
          {/* Slideshow */}
          <Slide easing="ease">
            {slideImages.map((slideImage, index) => (
              <div className="each-slide" key={index}>
                <div
                  className="bg-center  bg-cover h-64 md:h-96 lg:h-[550px] "
                  style={{ backgroundImage: `url(${slideImage})` }}
                >
                  {/* Optional text or other content on the slide */}
                </div>
              </div>
            ))}
          </Slide>
        </div>
      </div>
    </div>
  );
};

export default About;
