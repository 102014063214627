import React, { useState } from "react";
import { Link } from "react-scroll";
import logo from "../assets/logo.png";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="sticky top-0 z-20 w-full bg-white shadow-md">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex justify-between items-center">
          {/* Logo */}
          <Link
            to="hero"
            smooth={true}
            duration={1000}
            className="flex items-center py-5 px-2 text-gray-700 hover:text-gray-900 cursor-pointer"
          >
            <img src={logo} alt="logo" className="h-8 md:h-10" />
          </Link>

          {/* Mobile Menu Button */}
          <button onClick={toggleMobileMenu} className="md:hidden px-3 py-2">
            <svg
              className="w-6 h-6"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                d={
                  isMobileMenuOpen
                    ? "M6 18L18 6M6 6l12 12"
                    : "M4 6h16M4 12h16m-7 6h7"
                }
              />
            </svg>
          </button>

          {/* Primary Nav - Hidden in Mobile */}
          <div className="hidden md:flex space-x-1">
            <Link
              to="hero"
              smooth={true}
              duration={1000}
              className="py-5 px-3 text-gray-700 hover:text-gray-900 cursor-pointer"
            >
              Почетна
            </Link>
            <Link
              to="about"
              smooth={true}
              duration={1000}
              className="py-5 px-3 text-gray-700 hover:text-gray-900 cursor-pointer"
            >
              За Нас
            </Link>
            <Link
              to="jobs"
              smooth={true}
              duration={1000}
              className="py-5 px-3 text-gray-700 hover:text-gray-900 cursor-pointer"
            >
              Правни Области
            </Link>
            <Link
              to="team"
              smooth={true}
              duration={1000}
              className="py-5 px-3 text-gray-700 hover:text-gray-900 cursor-pointer"
            >
              Нашиот Тим
            </Link>
            <Link
              to="contact"
              smooth={true}
              duration={1000}
              className="py-5 px-3 text-gray-700 hover:text-gray-900 cursor-pointer"
            >
              Контакт
            </Link>
          </div>
        </div>

        {/* Mobile Menu */}
        <div className={`${isMobileMenuOpen ? "block" : "hidden"} md:hidden`}>
          <Link
            to="hero"
            smooth={true}
            duration={1000}
            className="block py-2 px-4 text-sm hover:bg-gray-200"
            onClick={toggleMobileMenu}
          >
            Почетна
          </Link>
          <Link
            to="about"
            smooth={true}
            duration={1000}
            className="block py-2 px-4 text-sm hover:bg-gray-200"
            onClick={toggleMobileMenu}
          >
            За Нас
          </Link>
          <Link
            to="jobs"
            smooth={true}
            duration={1000}
            className="block py-2 px-4 text-sm hover:bg-gray-200"
            onClick={toggleMobileMenu}
          >
            Правни Области
          </Link>
          <Link
            to="team"
            smooth={true}
            duration={1000}
            className="block py-2 px-4 text-sm hover:bg-gray-200"
            onClick={toggleMobileMenu}
          >
            Нашиот Тим
          </Link>
          <Link
            to="contact"
            smooth={true}
            duration={1000}
            className="block py-2 px-4 text-sm hover:bg-gray-200"
            onClick={toggleMobileMenu}
          >
            Контакт
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
