import logo from "./logo.svg";
import "./App.css";
import Hero from "./pages/Hero";
import About from "./pages/About";
import Jobs from "./pages/Jobs";
import SelfSection from "./pages/SelfSection";
import Team from "./pages/Team";
import Contact from "./pages/Contact";
import Footer from "./pages/Footer";
import Navbar from "./components/Navbar";

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <About />
      <Jobs />
      <SelfSection />
      <Team />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
