import React, { useState } from "react";
import Modal from "react-modal";
import team2 from "../assets/team2.jpg";
import team3 from "../assets/team3.jpg";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "auto",
    right: "0",
    bottom: "auto",
    transform: "translateY(-50%)",
    width: "300px",
    maxWidth: "100%",
    height: "100vh",
    border: "none",
    background: "white",
    padding: "0px",
    overflow: "auto",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "50",
  },
};

const Team = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  const teamMembers = [
    {
      id: 1,
      name: "М-р Хазрета Салоска",
      jobTitle: "Адвокатски стручен соработник",
      image: team2,
      description:
        "Дипломирала правни студии во 2009, магистрирала деловн право во 2013 година. Од 2015 година е вработена како стручен соработник во Адвокатска канцеларија Шериф Салоски, специјализирана е во областа на граѓанско и деловно право.",
    },
    // {
    //   id: 2,
    //   name: "Member 2",
    //   image: team3,
    //   description: "Description for Member 2...",
    // },
  ];

  const openModal = (member) => {
    setSelectedMember(member);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div id="team" className="bg-[#023047] flex flex-col py-10">
      <h2 className="text-3xl md:text-4xl font-bold self-center text-white mb-10">
        Нашиот Тим
      </h2>
      <div className="flex flex-wrap justify-center items-center">
        {teamMembers.map((member) => (
          <div key={member.id} className="relative m-4">
            <img
              src={member.image}
              alt={member.name}
              className="cursor-pointer rounded h-[300px] md:h-[500px] w-full object-cover"
              onClick={() => openModal(member)}
            />
            <div className="absolute bottom-0 bg-black bg-opacity-50 w-full text-white text-center py-2">
              {member.name}
            </div>
          </div>
        ))}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        {selectedMember && (
          <>
            <img
              src={selectedMember.image}
              alt={selectedMember.name}
              className="h-auto max-w-full w-full object-cover"
            />
            <div className="p-4">
              <h2 className="text-xl font-bold">{selectedMember.name}</h2>
              <h3 className="font-semibold text-[#f48c06] mb-4">
                {selectedMember.jobTitle}
              </h3>
              <p className="mt-2">{selectedMember.description}</p>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

export default Team;
