import React from "react";
import contact1 from "../assets/contact1.png";
import contact2 from "../assets/contact2.png";
import contact3 from "../assets/contact3.png";
import contactImg from "../assets/contactImg.jpg";

const Contact = () => {
  return (
    <div id="contact" className="mt-12 px-4 md:px-12">
      <div className="flex flex-col md:flex-row items-center justify-center gap-8">
        <div className="w-full md:w-1/2">
          <img
            className="w-full h-auto rounded-lg shadow-lg"
            src={contactImg}
            alt="contact"
          />
        </div>
        <div className="w-full md:w-1/2">
          <div className="flex justify-center mb-6">
            <h1 className="font-bold text-2xl md:text-3xl font-serif text-[#f48c06] border-b-2 border-[#f48c06]">
              Контакт
            </h1>
          </div>
          <div className="bg-[#023047] shadow-2xl rounded-lg p-4">
            <div className="flex items-center gap-4 mb-4">
              <img className="w-12 h-12" src={contact1} alt="contact1" />
              <p className="font-bold text-white">
                Кеј 8ми Ноември, број 8, Струга
              </p>
            </div>
            <div className="flex items-center gap-4 mb-4">
              <img className="w-12 h-12" src={contact2} alt="contact2" />
              <div>
                <p className="font-bold text-white">+389 75/555-477</p>
                <p className="font-bold text-white">+389 46/616-477</p>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <img className="w-12 h-12" src={contact3} alt="contact3" />
              <p className="font-bold text-white">advsaloski@yahoo.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
