import React from "react";
import lawVideo from "../assets/lawVideo.mp4";
import { Link } from "react-scroll";
const Hero = () => {
  return (
    <div id="hero" className="relative h-screen overflow-hidden">
      <video
        className="absolute top-0 left-0 w-full h-full object-cover"
        autoPlay
        loop
        muted
      >
        <source src={lawVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Overlay to darken the video */}
      <div className="absolute top-0 left-0 w-full h-full bg-[#000000cc]/[0.5] opacity-50"></div>

      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-white z-10 w-full">
        {/* Add your content here */}
        <h1 className="text-2xl font-bold mb-4 w-full">
          IUSTITIA EST CONSTANS ET PERPETUA VOLUNTAS IUS SUUM CUIQUE TRIBUENDI{" "}
        </h1>
        <p className="text-xl">
          Праведноста е постојана и непрекината волја на секого да му се даде
          неговото право.
        </p>
        <Link smooth={true} to="contact" duration={1000}>
          <button className="px-6 py-2 mt-6 bg-transparent border-2 text-white font-bold rounded hover:bg-[#023047] transition-all duration-500 relative overflow-hidden">
            Контакт
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Hero;
