import React from "react";
import "animate.css";
import team1 from "../assets/team1.jpg";

const SelfSection = () => {
  return (
    <div
      id="ceo"
      className="flex flex-col md:flex-row items-center justify-center mt-12 gap-8  p-8 rounded-lg "
    >
      <div className="w-full md:w-1/2 flex justify-center animate__animated animate__zoomIn">
        <img
          src={team1}
          alt="Team"
          className="rounded-lg shadow-md max-w-full h-auto"
          style={{ maxHeight: "700px" }}
        />
      </div>

      <div className="w-full md:w-1/2 animate__animated animate__fadeInUp">
        <div className="text-center md:text-left flex flex-col">
          <h1 className="text-4xl font-bold text-[#023047] mr-10 self-center mb-4">
            М-р Шериф Салоски
          </h1>
          <h2 className="text-2xl font-semibold self-center mr-10 text-[#f48c06] mb-4">
            Адвокат
          </h2>
          <p className="text-xl text-gray-700">
            Правни студии ги има завршено на Универзитетот „Св. Кирил и Методиј“
            на Правниот факултет „Јустинијан Први“ во Скопје.
            <br />
            Дипломирал 2009 година, магистрирал казнено право во 2012 година.
            <br />
            По дипломирањето започнува со работа како практикант во Адвокатска
            канцеларија во Скопје.
            <br />
            Во 2014 година започнува како практикант во Основен Суд Струга.
            <br />
            <br />
            Во 2015 година ја основа Адвокатска канцеларија Шериф Салоски.
            <br />
            <br />
            Адвокатска канцеларија Шериф Салоски е овластен регистрационен агент
            при Централен регистар на Р. Северна Македонија.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SelfSection;
