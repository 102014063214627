import React from "react";
import job1 from "../assets/job1.png";
import job2 from "../assets/job2.png";
import job3 from "../assets/job3.png";
import job4 from "../assets/job4.png";
import job5 from "../assets/job5.png";
import job6 from "../assets/job6.png";

const Jobs = () => {
  return (
    <div id="jobs" className="flex flex-col bg-[#023047]">
      <h2 className="text-4xl font-bold mt-20 self-center text-white">
        Правни Области
      </h2>

      <div className="flex flex-wrap justify-center bg-[#023047] p-[42px] gap-[40px] mt-20">
        <div className="flex flex-col items-center hover:-translate-y-1 hover:scale-110 duration-300 cursor-pointer mb-6">
          <img className="w-36 h-36" src={job1} alt="law1" />
          <p className="mt-4 font-bold text-white text-[24px]">
            Трговско право
          </p>
        </div>
        <div className="flex flex-col items-center hover:-translate-y-1 hover:scale-110 duration-300 cursor-pointer mb-6">
          <img className="w-36 h-36" src={job2} alt="law2" />
          <p className="mt-4 font-bold text-white text-[24px]">
            Граѓанско право
          </p>
        </div>
        <div className="flex flex-col items-center hover:-translate-y-1 hover:scale-110 duration-300 cursor-pointer mb-6">
          <img className="w-36 h-36" src={job3} alt="law3" />
          <p className="mt-4 font-bold text-white text-[24px]">
            Наследно право
          </p>
        </div>
        <div className="flex flex-col items-center hover:-translate-y-1 hover:scale-110 duration-300 cursor-pointer mb-6">
          <img className="w-36 h-36" src={job4} alt="law4" />
          <p className="mt-4 font-bold text-white text-[24px]">Трудово право</p>
        </div>
        <div className="flex flex-col items-center hover:-translate-y-1 hover:scale-110 duration-300 cursor-pointer mb-6">
          <img className="w-36 h-36" src={job5} alt="law5" />
          <p className="mt-4 font-bold text-white text-[24px]">Семејно право</p>
        </div>
        <div className="flex flex-col items-center hover:-translate-y-1 hover:scale-110 duration-300 cursor-pointer mb-6">
          <img className="w-36 h-36" src={job6} alt="law6" />
          <p className="mt-4 font-bold text-white text-[24px]">
            Работни односи
          </p>
        </div>
      </div>
    </div>
  );
};

export default Jobs;
