import React from "react";
import logo from "../assets/logo.png";
import contact1 from "../assets/contact1.png";
import contact2 from "../assets/contact2.png";
import contact3 from "../assets/contact3.png";

const Footer = () => {
  return (
    <div id="footer" className="bg-[#023047] w-full border-t-4 b opacity-90 ">
      <div className="flex flex-col-reverse gap-12 sm:flex-row justify-between p-6">
        <img
          className="cursor-pointer w-[200px] h-[40px]"
          src={logo}
          alt="logo"
        />

        <div>
          <h1 className=" text-white font-bold text-[20px] font-serif text-[#f48c06] border-b-2 border-[#f48c06] w-[90px] ">
            Препознатливост
          </h1>
          <p className="text-white font-medium mt-2">
            Она што не прави препознатливи и различни од другите адвокатски
            <br />
            канцеларии е примената на висока корпоративна култура во <br />
            работењето, со практикување на високи морални и етички вредности.
            <br />
            Нашата основна мисија е задоволен и среќен клиент, што може да се
            <br />
            постигне само со посветено работење и грижа за интересите на
            клиентот.
          </p>
        </div>
        <div className="flex flex-col gap-5 mt-2 text-white">
          <h1 className="font-bold text-[20px] font-serif text-[#f48c06] border-b-2 border-[#f48c06] w-[80px]">
            Консултации
          </h1>
          <div className="flex gap-2">
            <img className="w-6" src={contact1} alt="contact1" />
            <p>Кеј 8ми Ноември, број 8, Струга</p>
          </div>
          <div className="flex gap-2">
            <img
              className="w-6 h-6 justify-center flex"
              src={contact2}
              alt="contact3"
            />
            <p>
              +389 75/555-477
              <br /> +389 46/616-477
            </p>
          </div>
          <div className="flex gap-2">
            <img className="w-6" src={contact3} alt="contact3" />
            <p>advsaloski@yahoo.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
